import View1 from "./view.jpg"
import View2 from "./view2.avif"
import View3 from "./view3.avif"
import View4 from "./view1.jpg"
import View5 from "./view4.jpg"


export const Slides = [
    {
      id:1,
      img:View1,
      
    },
    {
      id:2,
      img:View2,
      
    },
    {
      id:3,
      img: View3,
      
    },
    {
      id:4,
      img: View4,
      
    },
    {
      id:5,
      img: View5,
      
    },
  
  ];

  